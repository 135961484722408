import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastManager } from '@blocks/toast/toast.manager';
import { ValidationHelper } from '@helpers/validation.helper';
import { CompanyProfile } from '@interfaces/corporate.interface';
import { OnBoardingService } from '@services/onboarding.service';
import { StoreService } from '@services/store.service';
import CustomEditor from 'ckeditor5-ng';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { MAX_LENGTH } from 'src/app/shared/constants';

@Component({
  selector: 'on-boarding-form',
  templateUrl: './on-boarding-form.component.html',
  styleUrls: ['./on-boarding-form.component.scss',
    '../interview-form/interview-form.component.scss',
    '../../../../pages/job/job-detail/job-detail.component.scss',
    '../../../../pages/talent/about/about.component.scss']
})
export class OnBoardingFormComponent {

  @Input() onBoardingId!: string;
  public isLoading = false;
  public minDate = dayjs().format('YYYY-MM-DD');
  public onboardingForm !: FormGroup<{
    description: FormControl<string>,
    onboardingDate: FormControl<string>,
    onboardingTime: FormControl<string>,
    officeId: FormControl<string>,
  }>;
  public Editor = CustomEditor;
  public branchList: any[] = [];
  public companyProfile!: CompanyProfile | null;

  constructor
    (
      private onBoardingService: OnBoardingService,
      private toastManager: ToastManager,
      private storeService: StoreService,
    ) {

    this.onboardingForm = new FormGroup({
      description: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required, ValidationHelper.customCkEditorLengthValidator(MAX_LENGTH.TEXT_AREA_LARGE)], nonNullable: true }),
      onboardingDate: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      onboardingTime: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
      officeId: new FormControl<string>({
        value: '',
        disabled: false
      }, { validators: [Validators.required], nonNullable: true }),
    }, { validators: [ValidationHelper.minTodayValidator] });
  }

  async ngOnInit() {
    this.storeService.companyProfile$.subscribe(companyProfile => {
      this.companyProfile = companyProfile;
      if (this.companyProfile) {
        if (!isEmpty(this.companyProfile?.mainOffice)) {
          this.branchList = [this.companyProfile.mainOffice];
        }

        if (!isEmpty(this.companyProfile?.branches)) {
          this.branchList = this.branchList.concat(this.companyProfile.branches)
        }
      }
    })
  }

  private mapOnboardingPayload() {
    const description = this.onboardingForm.get('description')?.getRawValue();
    const date = this.onboardingForm.get('onboardingDate')?.getRawValue();
    const time = this.onboardingForm.get('onboardingTime')?.getRawValue();
    const officeId = this.onboardingForm.get('officeId')?.getRawValue();
    return {
      onboardingId: this.onBoardingId,
      message: description,
      date,
      time,
      officeId,
    }
  }

  public async sendOnboardingMeeting() {
    try {
      this.isLoading = true;
      if (!this.onboardingForm.valid) {
        this.toastManager.showError('Onboarding invitation failed', 'Please complete interview form first');
        return;
      }
      const payload = this.mapOnboardingPayload();
      await this.onBoardingService.sendOnboardingInvitation(payload);
      this.toastManager.showSuccess('Your onboarding invitation has submitted successfully');
      this.onBoardingService.setSelectedOnBoardingId(this.onBoardingId)
    } catch (error: any) {
      this.toastManager.showError('Onboarding invitation failed', error.message);
    } finally {
      this.isLoading = false
    }
  }

  public getWordCount() {
    const description = this.onboardingForm.get('description')?.value;
    const words = description && description.trim().split(/\s+/);
    return words?.length || 0;
  }
}
