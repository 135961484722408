export const PASSWORD_PATTERN = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&(),.?":{}|<>]).*$/;
export const PHONE_NUMBER_PATTERN = /^[1-9][0-9]*$/;
export const URL_PATTERN = /^(?:(?:https?|ftp):\/\/)?(?:[a-z0-9-]+\.)+[a-z]{2,}(?:\/[^\s]*)?$/i;
// export const URL_PATTERN = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]{1,255}$/;
// export const URL_PATTERN = /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]{1,255}$/;
export const ALPHABET_PATTERN = /^[a-zA-Z\s]+$/;
export const ALPHANUMERIC_PATTERN = /^[a-zA-Z0-9\s.&\-_(),\/]{3,150}$/;

export const MAX_LENGTH = {
  INPUT: 50,
  INPUT_MEDIUM: 100,
  TEXT_AREA_LARGE: 2000,
  TEXT_AREA_SMALL: 250,
  PHONE: 15,
  SKILL: 20,
  INDUSTRY: 3,
}

export const LIMIT_GET_LIST = 20;

export const MAX_FILE_SIZE = 10; //MB
