<div class="form-container">
  <app-spinner *ngIf="isLoading"></app-spinner>
  <div class="form-title">
    <div class="title-content">
      <div class="title-text">
        Education </div>
      <svg class="close-icon" (click)="closeModal()" width="24" height="24" viewBox="0 0 16 16" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
          fill="#757575" />
      </svg>
    </div>
    <div class="break-line">
    </div>
  </div>
  <form class="form-body-education" [formGroup]="educationForm" (ngSubmit)="onClickSubmit()">
    <div class="form-list" formArrayName="educationEntries">
      <div *ngFor="let entry of educationEntries.controls; let i = index" [formGroupName]="i">
        <div class="form">
          <div class="form-group-label-input">
            <div class="form-label-input">
              Institution Name
            </div>
            <div class="ec-input-group-rounded input-text-space">
              <span class="ec-input-group-text">
                <i class="bi bi-building"></i>
              </span>
              <input type="text" formControlName="schoolName" class="ec-form-control-rounded ec-is-invalid"
                placeholder="Institution Name">
              <div class="ec-invalid-feedback"
                *ngIf="educationEntries.controls[i]?.get('schoolName')?.touched && educationEntries.controls[i]?.get('schoolName')?.hasError('required') && !educationEntries.controls[i]?.get('schoolName')?.hasError('pattern')">
                Institution name is required
              </div>
              <div class="ec-invalid-feedback"
                *ngIf="educationEntries.controls[i]?.get('schoolName')?.touched && educationEntries.controls[i]?.get('schoolName')?.hasError('pattern')">
                Institution name should be a alphanumeric and minimum 3 characters
              </div>
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Education Level
            </div>
            <div class="ec-input-group-rounded">
              <span class="ec-input-group-text">
                <i class="bi bi-book-fill"></i>
              </span>
              <select type="text" formControlName="degree" class="ec-form-select-rounded">
                <option value="" disabled selected>Education Level</option>
                <option *ngFor="let education of educations" [value]="education.value">{{ education.value }}</option>
              </select>
            </div>
            <div class="invalid-feedback-text"
              *ngIf="educationEntries.controls[i]?.get('degree')?.touched && educationEntries.controls[i]?.get('degree')?.hasError('required')">
              Education Level is required
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              Start Date
            </div>
            <div class="ec-input-group-rounded">
              <span class="ec-input-group-text">
                <i class="bi bi-calendar2-week-fill"></i>
              </span>
              <input type="text" formControlName="startDate" class="ec-form-control-rounded ec-is-invalid"
                placeholder="Start Date" onfocus="(this.type='date')" min="1970-01-01" [max]="maxDate">
              <div class="ec-invalid-feedback"
                *ngIf="educationEntries.controls[i]?.get('startDate')?.touched && educationEntries.controls[i]?.get('startDate')?.hasError('required')">
                Start Date is required
              </div>
              <div class="ec-invalid-feedback"
                *ngIf="educationEntries.controls[i]?.get('startDate')?.touched && educationEntries.controls[i]?.hasError('startDateMoreThanToday')">
                Start date can't be more than today
              </div>
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="checkbox-parent">
              <input type="checkbox" (change)="onStillAttendClick(i)" name="privacyPolicyCheck"
                formControlName="stillAttend" class="ec-form-check-input-sm ec-is-invalid">
              <div class="sub">
                <span>I'm still attending</span>
              </div>
            </div>
          </div>
          <div class="form-group-label-input">
            <div class="form-label-input">
              End Date
            </div>
            <div class="ec-input-group-rounded">
              <span class="ec-input-group-text">
                <i class="bi bi-calendar2-week-fill"></i>
              </span>
              <input type="text" formControlName="endDate" class="ec-form-control-rounded ec-is-invalid"
                placeholder="End Date" onfocus="(this.type='date')" min="1970-01-01" [max]="maxDate">
              <div class="ec-invalid-feedback"
                *ngIf="educationEntries.controls[i]?.get('endDate')?.touched && educationEntries.controls[i]?.hasError('endDateLessThanStartDate')">
                End date can't be less than start date!
              </div>
              <div class="ec-invalid-feedback"
                *ngIf="educationEntries.controls[i]?.get('startDate')?.touched && educationEntries.controls[i]?.hasError('endDateMoreThanToday')">
                End date can't be more than today
              </div>
              <div class="ec-invalid-feedback"
                *ngIf="educationEntries.controls[i]?.get('endDate')?.touched && educationEntries.controls[i]?.get('endDate')?.hasError('required')">
                End date is required
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="break-line">
    </div>
    <div class="button-group">
      <button type="submit" [disabled]="!educationForm.valid || isLoading"
        class="button-save ec-btn ec-btn-sm ec-btn-primary ec-btn-rounded mb-2 me-2">
        Save
        <i class="bi bi-arrow-right">
        </i>
      </button>
    </div>
  </form>
</div>
