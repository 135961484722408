import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastManager } from '@blocks/toast/toast.manager';
import { DateHelper } from '@helpers/date.helper';
import { ValidationHelper } from '@helpers/validation.helper';
import { Reference } from '@interfaces/reference.interface';
import { WorkHistory } from '@interfaces/talent.interface';
import { ReferenceService } from '@services/reference.service';
import { TalentService } from '@services/talent.service';
import CustomEditor from 'ckeditor5-ng';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { ALPHANUMERIC_PATTERN, MAX_LENGTH } from 'src/app/shared/constants';

@Component({
  selector: 'app-work-history-form',
  templateUrl: './work-history-form.component.html',
  styleUrls: ['../../../talent/about/about.component.scss']
})
export class WorkHistoryFormComponent implements AfterViewInit {
  public oldWorkHistory !: WorkHistory;
  public formType = 'NEW';
  public workPositions: Reference[] = [];
  public workHistoryForm !: FormGroup;
  public isLoading = false;
  public Editor = CustomEditor;
  public maxDate = dayjs().format('YYYY-MM-DD');

  constructor
    (
      private talentService: TalentService,
      private referenceService: ReferenceService,
      private dialogRef: MatDialogRef<WorkHistoryFormComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private toastManager: ToastManager,
    ) {
    this.workHistoryForm = new FormGroup({
      workHistoryEntries: new FormArray([]),
    });
    this.addWorkHistoryEntry({});
    if (modalData?.data?.id) {
      this.formType = 'EDIT'
      this.oldWorkHistory = modalData.data;
      this.mapEditedWorkHistory()
    }
  }

  async ngOnInit() {
    try {
      this.isLoading = true;
      this.workPositions = await this.referenceService.getReferenceListByType('WORK_POSITION');
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public closeModal() {
    this.dialogRef.close()
  }

  get workHistoryEntries() {
    return this.workHistoryForm.get('workHistoryEntries') as FormArray;
  }

  addWorkHistoryEntry(experience: any) {
    if (this.workHistoryEntries.length > 5) {
      this.toastManager.showError(null, 'You can only add 5 experience entries')
      return;
    }
    this.workHistoryEntries.push(this.workHistoryFormEntry(experience));
  }

  private mapEditedWorkHistory() {
    const formControl = this.workHistoryEntries.controls[0];
    formControl.get('companyName')?.setValue(this.oldWorkHistory.companyName)
    formControl.get('position')?.setValue(this.oldWorkHistory.position)
    formControl.get('jobDetail')?.setValue(this.oldWorkHistory.jobDetail || '')
    formControl.get('startDate')?.setValue(dayjs(this.oldWorkHistory.startDate).format('DD/MM/YYYY'))
    formControl.get('stillAttend')?.setValue(false)
    if (this.oldWorkHistory.stillAttend) {
      formControl.get('stillAttend')?.setValue(true)
      formControl.get('endDate')?.disable();
      return
    }
    formControl.get('endDate')?.setValue(dayjs(this.oldWorkHistory.endDate).format('DD/MM/YYYY'))
  }

  private workHistoryFormEntry(experience: WorkHistory): FormGroup {
    let startDate = '';
    if (experience.startDate) {
      startDate = dayjs(experience.startDate).format('DD/MM/YYYY')
    }

    let endDate = '';
    if (!isNil(experience.stillAttend) && !experience.stillAttend) {
      endDate = dayjs(experience.endDate).format('DD/MM/YYYY')
    }

    const workHistoryEntry = new FormGroup({
      companyName: new FormControl(experience.companyName, [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHANUMERIC_PATTERN)]),
      position: new FormControl(experience.position || '', Validators.required),
      jobDetail: new FormControl(experience.jobDetail, [Validators.required, ValidationHelper.customCkEditorLengthValidator()]),
      startDate: new FormControl(startDate, Validators.required),
      stillAttend: new FormControl(!!experience.stillAttend),
      endDate: new FormControl(endDate, Validators.required),
    }, { validators: [ValidationHelper.endDateValidator, ValidationHelper.maxTodayValidator] });

    if (experience.stillAttend) {
      workHistoryEntry.get('endDate')?.disable()
    }

    return workHistoryEntry;
  }

  public getWordCount(index: number) {
    const formControl = this.workHistoryEntries.controls[index];
    const jobDetail = formControl.get('jobDetail')?.value;
    const words = jobDetail && jobDetail.trim().split(/\s+/);
    return words?.length || 0;
  }

  onStillAttendClick(index: number) {
    const formControl = this.workHistoryEntries.controls[index];
    const stillAttend = formControl.get('stillAttend')?.value;

    if (stillAttend) {
      formControl.get('endDate')?.disable();
      formControl.get('endDate')?.reset();
      return;
    }

    formControl.get('endDate')?.enable();
  }

  public onClickSubmit() {
    if (this.formType === 'NEW') {
      return this.createNewWorkHistory()
    }

    return this.editWorkHistory()
  }

  protected mapWorkHistoryPayload() {
    const workHistoryList = this.workHistoryEntries.value.map((workHistory: WorkHistory) => {
      return {
        ...workHistory,
        endDate: workHistory.stillAttend ? workHistory.startDate : workHistory.endDate
      };
    });
    const workHistoryPayload = {
      experiences: workHistoryList
    };
    return workHistoryPayload;
  }

  private async editWorkHistory(): Promise<void> {
    try {
      this.isLoading = true;
      const workPayload = this.mapWorkHistoryPayload();
      const updatedWorkHistory = workPayload.experiences[0];
      updatedWorkHistory.startDate = DateHelper.convertDateToYMD(updatedWorkHistory.startDate)
      updatedWorkHistory.endDate = DateHelper.convertDateToYMD(updatedWorkHistory.endDate)
      await this.talentService.editWorkHistory(this.oldWorkHistory.id, updatedWorkHistory);
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('Work Experience saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private async createNewWorkHistory(): Promise<void> {
    try {
      this.isLoading = true;
      const educationPayload = this.mapWorkHistoryPayload();
      await this.talentService.createWorkHistory(educationPayload);
      this.toastManager.showSuccess('Work Experience saved successfully')
      await this.modalData.callback()
      this.dialogRef.close()
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
