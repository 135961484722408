// Angular modules
import { Injectable } from '@angular/core';

import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAX_FILE_SIZE, MAX_LENGTH } from '../constants';
import * as dayjs from 'dayjs';

@Injectable()
export class ValidationHelper {

  public static endDateValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const { startDate, endDate, stillAttend } = control.value;
    if (!endDate) {
      return null;
    }
    const isEndDateLessThanStartDate = new Date(endDate) <= new Date(startDate);
    let validationItem: any = {};
    if (isEndDateLessThanStartDate) {
      validationItem.endDateLessThanStartDate = true;
    }
    if (stillAttend) {
      control.get('endDate')?.clearValidators;
      control.get('endDate')?.setValue(control.get('endDate')?.value)
    }
    return validationItem
  }

  public static confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    return control.value.password === control.value.confirmationPassword
      ? null
      : { PasswordNoMatch: true };
  }

  public static customCkEditorLengthValidator(maxLength: number = MAX_LENGTH.TEXT_AREA_SMALL): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const textAreaValue = control.value;
      const words = textAreaValue && textAreaValue.trim().split(/\s+/) || [];
      return words.length <= maxLength ? null : { textAreaMaxLength: true };
    }
  }

  public static uploadFileValidator(inputFile: HTMLInputElement) {
    if (inputFile.files && inputFile.files.length > 0) {
      const fileSize = inputFile.files[0].size;
      const fileSizeInMB: number = fileSize / (1024 * 1024);
      if (fileSizeInMB > MAX_FILE_SIZE) {
        throw new Error(`Maximum file size is ${MAX_FILE_SIZE}MB`)
      }
    }
  }

  public static maxTodayValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const { startDate, endDate, dateOfBirth } = control.value;
    const isStartDateThanToday = new Date(startDate) > new Date();
    const isEndDateThanToday = new Date(endDate) > new Date();
    const isDobThanToday = new Date(dateOfBirth) > new Date();

    let validationItem: any = {};
    if (isStartDateThanToday) {
      validationItem.startDateMoreThanToday = true;
    }
    if (isEndDateThanToday) {
      validationItem.endDateMoreThanToday = true;
    }

    if (isDobThanToday) {
      validationItem.dobMoreThanToday = true;
    }
    return validationItem
  }

  public static minTodayValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const { scheduledDate, onboardingDate } = control.value;
    const isScheduleDateThanToday = new Date(scheduledDate) < new Date();
    const isOnboardingDateThanToday = new Date(onboardingDate) < new Date();

    let validationItem: any = {};
    if (isScheduleDateThanToday) {
      validationItem.scheduleDateLessThanToday = true;
    }
    if (isOnboardingDateThanToday) {
      validationItem.onboardingDateLessThanToday = true;
    }
    return validationItem
  }

  public static dobMoreThanTodayValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const dob = dayjs(control.value);
    const today = dayjs();
    return dob.isAfter(today, 'day') ? { dobMoreThanToday: true } : null;
  };
}
