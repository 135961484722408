import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastManager } from '@blocks/toast/toast.manager';
import { DateHelper } from '@helpers/date.helper';
import { ValidationHelper } from '@helpers/validation.helper';
import { Reference } from '@interfaces/reference.interface';
import { Education, UserProfile } from '@interfaces/talent.interface';
import { ReferenceService } from '@services/reference.service';
import { StoreService } from '@services/store.service';
import { TalentService } from '@services/talent.service';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { ALPHANUMERIC_PATTERN, MAX_LENGTH } from 'src/app/shared/constants';

@Component({
  selector: 'app-education-form',
  templateUrl: './education-form.component.html',
  styleUrls: ['./education-form.component.scss', '../../../talent/about/about.component.scss']
})
export class EducationFormComponent implements AfterViewInit {
  public oldEducation !: Education;
  public formType = 'NEW';
  public userProfile !: UserProfile | null;
  public educationForm !: FormGroup;
  public educations: Reference[] = [];
  public isLoading = false;
  public maxDate = dayjs().format('YYYY-MM-DD');

  constructor
    (
      private talentService: TalentService,
      private referenceService: ReferenceService,
      private dialogRef: MatDialogRef<EducationFormComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private storeService: StoreService,
      private toastManager: ToastManager,
    ) {
    this.educationForm = new FormGroup({
      educationEntries: new FormArray([]),
    });
    this.addEducationEntry({});
    if (modalData?.data?.id) {
      this.formType = 'EDIT'
      this.oldEducation = modalData.data;
      this.mapEditedEducation()
    }

    this.storeService.userProfile$.subscribe((userProfile) => {
      this.userProfile = userProfile;
    });
  }

  private educationFormEntry(educationEntryData: Education): FormGroup {
    let startDate = '';
    if (educationEntryData.startDate) {
      startDate = dayjs(educationEntryData.startDate).format('DD/MM/YYYY')
    }

    let endDate = '';
    if (!isNil(educationEntryData.stillAttend) && !educationEntryData.stillAttend) {
      endDate = dayjs(educationEntryData.endDate).format('DD/MM/YYYY')
    }
    const educationEntry = new FormGroup({
      schoolName: new FormControl(educationEntryData.schoolName, [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHANUMERIC_PATTERN)]),
      degree: new FormControl(educationEntryData.degree || '', Validators.required),
      startDate: new FormControl(startDate, [Validators.required]),
      stillAttend: new FormControl(!!educationEntryData.stillAttend),
      endDate: new FormControl(endDate, Validators.required),
    }, { validators: [ValidationHelper.endDateValidator, ValidationHelper.maxTodayValidator], });

    if (educationEntryData.stillAttend) {
      educationEntry.get('endDate')?.disable()
    }

    return educationEntry;
  }

  addEducationEntry(educationEntryData: any) {
    if (this.educationEntries.length > 5) {
      this.toastManager.showError(null, 'You can only add 5 education entries')
      return;
    }
    this.educationEntries.push(this.educationFormEntry(educationEntryData));
  }

  async ngOnInit() {
    try {
      this.isLoading = true;
      this.educations = await this.referenceService.getReferenceListByType('EDUCATION');
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public closeModal() {
    this.dialogRef.close()
  }

  get educationEntries() {
    return this.educationForm.get('educationEntries') as FormArray;
  }

  onStillAttendClick(index: number) {
    const formControl = this.educationEntries.controls[index];
    const stillAttend = formControl.get('stillAttend')?.value;

    if (stillAttend) {
      formControl.get('endDate')?.disable();
      formControl.get('endDate')?.reset();
      return;
    }

    formControl.get('endDate')?.enable();
  }


  private mapEditedEducation() {
    const formControl = this.educationEntries.controls[0];
    if (formControl) {
      formControl.get('schoolName')?.setValue(this.oldEducation.schoolName)
      formControl.get('degree')?.setValue(this.oldEducation.degree)
      formControl.get('startDate')?.setValue(dayjs(this.oldEducation.startDate).format('DD/MM/YYYY'))
      formControl.get('stillAttend')?.setValue(false)
      if (this.oldEducation.stillAttend) {
        formControl.get('stillAttend')?.setValue(true)
        formControl.get('endDate')?.disable();
        return
      }
      formControl.get('endDate')?.setValue(dayjs(this.oldEducation.endDate).format('DD/MM/YYYY'))
    }
  }

  public onClickSubmit() {
    if (this.formType === 'NEW') {
      return this.createEducation()
    }

    return this.editEducation()
  }

  protected mapEducationPayload() {
    const educationList: any = this.educationEntries.value.map((education: Education) => {
      return {
        schoolName: education.schoolName,
        degree: education.degree,
        startDate: education.startDate,
        endDate: education.stillAttend ? education.startDate : education.endDate,
        stillAttend: education.stillAttend
      };
    });
    const educationPayload = {
      educations: educationList
    };
    return educationPayload;
  }

  private async editEducation(): Promise<void> {
    try {
      this.isLoading = true;
      const educationPayload = this.mapEducationPayload();
      const updatedEducation = educationPayload.educations[0];
      updatedEducation.startDate = DateHelper.convertDateToYMD(updatedEducation.startDate)
      updatedEducation.endDate = DateHelper.convertDateToYMD(updatedEducation.endDate)

      await this.talentService.editEducation(this.oldEducation.id, updatedEducation);
      await this.modalData.callback()
      this.dialogRef.close()
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private async createEducation(): Promise<void> {
    try {
      this.isLoading = true;
      if (this.userProfile && this.userProfile.education?.length > 5) {
        const errorMessage = 'You can only add 5 education, please edit or remove current your education';
        this.toastManager.showError(null, errorMessage)
        return;
      }
      const educationPayload = this.mapEducationPayload();
      await this.talentService.createEducation(educationPayload);
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('Education Information saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
