import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import CustomEditor from 'ckeditor5-ng';
import dayjs from 'dayjs';

import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastManager } from '@blocks/toast/toast.manager';
import { DateHelper } from '@helpers/date.helper';
import { Portfolio } from '@interfaces/talent.interface';
import { TalentService } from '@services/talent.service';
import { isNil } from 'lodash';
import { ValidationHelper } from '@helpers/validation.helper';
import { ALPHANUMERIC_PATTERN, MAX_LENGTH, URL_PATTERN } from 'src/app/shared/constants';


@Component({
  selector: 'app-portfolio-form',
  templateUrl: './portfolio-form.component.html',
  styleUrls: ['../../../talent/about/about.component.scss']
})
export class PortfolioFormComponent implements AfterViewInit {
  public skillForm !: FormGroup;
  public oldPortfolio !: Portfolio;
  public formType = 'NEW';
  public Editor = CustomEditor;
  public isLoading = false;
  public maxDate = dayjs().format('YYYY-MM-DD');

  constructor
    (
      private talentService: TalentService,
      private router: Router,
      private dialogRef: MatDialogRef<PortfolioFormComponent>,
      @Inject(MAT_DIALOG_DATA) public modalData: any,
      private cdr: ChangeDetectorRef,
      private toastManager: ToastManager,
    ) {
    this.skillForm = new FormGroup({
      portfolioEntries: new FormArray([]),
    });
    if (modalData?.data?.id) {
      this.formType = 'EDIT'
      this.oldPortfolio = modalData.data;
      this.addPortofolioEntry(this.oldPortfolio)
      return
    }
    this.addPortofolioEntry({})

  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  public closeModal() {
    this.dialogRef.close()
  }

  public isPortfolioFormValid() {
    const portfolioEntriesArray = this.skillForm.get('portfolioEntries') as FormArray;
    const allPortfolioEntriesValid = portfolioEntriesArray.controls.every((control) => control.valid);
    return allPortfolioEntriesValid
  }

  private portofolioFormEntry(portfolio: Portfolio): FormGroup {
    let startDate = '';
    if (portfolio.startDate) {
      startDate = dayjs(portfolio.startDate).format('DD/MM/YYYY')
    }

    let endDate = '';
    if (!isNil(portfolio.isOngoing) && !portfolio.isOngoing) {
      endDate = dayjs(portfolio.endDate).format('DD/MM/YYYY')
    }

    const portfolioEntry = new FormGroup({
      projectName: new FormControl(portfolio.projectName, [Validators.required, Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(ALPHANUMERIC_PATTERN)]),
      description: new FormControl(portfolio.description, [Validators.required, ValidationHelper.customCkEditorLengthValidator()]),
      role: new FormControl(portfolio.role, [Validators.required, Validators.pattern(ALPHANUMERIC_PATTERN)]),
      startDate: new FormControl(startDate, Validators.required),
      isOngoing: new FormControl(!!portfolio.isOngoing),
      endDate: new FormControl(endDate, Validators.required),
      url: new FormControl(portfolio.url, [Validators.maxLength(MAX_LENGTH.INPUT), Validators.pattern(URL_PATTERN)])
    }, { validators: [ValidationHelper.endDateValidator, ValidationHelper.maxTodayValidator] });

    if (portfolio.isOngoing) {
      portfolioEntry.get('endDate')?.disable()
    }

    return portfolioEntry;
  }


  get portfolioEntries() {
    return this.skillForm.get('portfolioEntries') as FormArray;
  }


  public onClickSubmit() {
    if (this.formType === 'NEW') {
      return this.createNewPortfolio()
    }

    return this.editPortfolio()
  }

  protected mapPortfolioPayload() {
    return this.portfolioEntries.value.map((portfolio: Portfolio) => {
      return {
        ...portfolio,
        endDate: portfolio.isOngoing ? portfolio.startDate : portfolio.endDate,
      }
    });
  }

  addPortofolioEntry(portfolio: any) {
    if (this.portfolioEntries.length > 10) {
      this.toastManager.showError(null, 'You can only add 10 portfolio entries')
      return;
    }

    this.portfolioEntries.push(this.portofolioFormEntry(portfolio));
  }


  public getWordCount(index: number) {
    const formControl = this.portfolioEntries.controls[index];
    const description = formControl.get('description')?.value;
    const words = description && description.trim().split(/\s+/);
    return words?.length || 0;
  }

  onIsOngoingClick(index: number) {
    const formControl = this.portfolioEntries.controls[index];
    const isOngoing = formControl.get('isOngoing')?.value;

    if (isOngoing) {
      formControl.get('endDate')?.disable();
      formControl.get('endDate')?.reset();
      return;
    }

    formControl.get('endDate')?.enable();
  }


  private async editPortfolio(): Promise<void> {
    try {
      this.isLoading = true;
      const portfolioPayload = this.mapPortfolioPayload();
      const updatedPortfolio = portfolioPayload[0];
      updatedPortfolio.startDate = DateHelper.convertDateToYMD(updatedPortfolio.startDate)
      updatedPortfolio.endDate = DateHelper.convertDateToYMD(updatedPortfolio.endDate)
      await this.talentService.editPortfolio(this.oldPortfolio.id, updatedPortfolio);
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('Portfolio updated successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }

  private async createNewPortfolio(): Promise<void> {
    try {
      this.isLoading = true;
      const portfolioPayload = this.mapPortfolioPayload();
      const newPortfolio = portfolioPayload[0];
      await this.talentService.createPortfolio(newPortfolio);
      await this.modalData.callback()
      this.dialogRef.close()
      this.toastManager.showSuccess('Portfolio saved successfully')
    } catch (error: any) {
      this.toastManager.showError(null, error.message)
    } finally {
      this.isLoading = false;
    }
  }
}
